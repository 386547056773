import React from 'react';
import "./Home.scss";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from './Dashhead';
import axios from 'axios';
import { connect } from 'react-redux';
import { getUserInfo } from './redux/user/userActions';
import GraphComponent from './graph';

function Home(props) {
  const [display, setDisplay] = React.useState(false);
  const [dashboardData, setDashboardData] = React.useState({
    totalPreApprovedAmountToday: 0,
    totalPreApprovalConsumedToday: 0,
    totalPreApprovedAmountThisMonth: 0,
    totalPreApprovalConsumedThisMonth: 0,
    totalPreApprovedCasesToday: 0,
    totalOrderCasesToday: 0,
    totalPreApprovedCasesThisMonth: 0,
    totalOrderCasesThisMonth: 0,
  });

  React.useEffect(() => {
    // Fetch user info if not already fetched
    if (!props.user.userInfo) {
      props.getUserInfo(props.user.user);
      return;
    }

    // Fetch dashboard data using userInfo.groupId
    const fetchDashboardData = async () => {
      try {
        const groupId = props.user.userInfo.groupId; // Use groupId from userInfo
        if (!groupId) return;

        const response = await axios.get(
          `https://prod.cleverpe.com/users/partner/dash/${groupId}`,
          {
            headers: {
              token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoicGFydG5lciIsIm1vYmlsZU5vIjoiNzc3Nzc3Nzc3NyIsImlhdCI6MTcyMjcwMzA4MSwiZXhwIjoxNzU0MjYwNjgxfQ.NB8dgwUd9NZB1c4EQD3NX8sf51PwN1IH1W4y85E_bXY', // Replace with dynamic token or auth mechanism
            },
          }
        );

        // Update dashboard data
        console.log(response.data)
        if (response.data?.result.data) {
          const {
            completedOrders,
            preApprovals,
          } = response.data.result.data;

          setDashboardData({
            totalPreApprovedAmountToday: preApprovals.preApprovalToday.totalAmount || 0,
            totalPreApprovalConsumedToday: completedOrders.amountConsumedToday.totalAmount || 0,
            totalPreApprovedAmountThisMonth: preApprovals.preApprovalThisMonth.totalAmount || 0,
            totalPreApprovalConsumedThisMonth: completedOrders.amountConsumedThisMonth.totalAmount || 0,
            totalPreApprovedCasesToday: preApprovals.preApprovalToday.docCount || 0,
            totalOrderCasesToday: completedOrders.amountConsumedToday.docCount || 0,
            totalPreApprovedCasesThisMonth: preApprovals.preApprovalThisMonth.docCount || 0,
            totalOrderCasesThisMonth: completedOrders.amountConsumedThisMonth.docCount || 0,
          });

        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, [props.user.userInfo]);

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <Dashhead id={1} display={display} />
      </div>

      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={() => display && setDisplay(false)}>
        <span className="iconbutton display-mobile">
          <IconButton size="large" aria-label="Menu" onClick={() => setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
          </IconButton>
        </span>

        <div className="notice-container">
          <h2>System Upgrade in Progress</h2>
          <p>
            Dear Partners, we are currently upgrading our system to improve your experience. During this time, some features and tabs will be temporarily unavailable. We expect the system to be fully operational soon. Thank you for your patience!
          </p>
        </div>

        <div className="insights-container">
          <div className="insight-box">
            <h4>Pre-Approved Amount (Today)</h4>
            <p className="amount">₹ {dashboardData.totalPreApprovedAmountToday}</p>
            <p className="cases">{dashboardData.totalPreApprovedCasesToday} Cases</p>
          </div>
          <div className="insight-box">
            <h4>Amount Consumed (Today)</h4>
            <p className="amount">₹ {dashboardData.totalPreApprovalConsumedToday}</p>
            <p className="cases">{dashboardData.totalOrderCasesToday} Cases</p>
          </div>
          <div className="insight-box">
            <h4>Pre-Approved Amount (This Month)</h4>
            <p className="amount">₹ {dashboardData.totalPreApprovedAmountThisMonth}</p>
            <p className="cases">{dashboardData.totalPreApprovedCasesThisMonth} Cases</p>
          </div>
          <div className="insight-box">
            <h4>Amount Consumed (This Month)</h4>
            <p className="amount">₹ {dashboardData.totalPreApprovalConsumedThisMonth}</p>
            <p className="cases">{dashboardData.totalOrderCasesThisMonth} Cases</p>
          </div>
        </div>

        <div className="profile-graph">
          <div className="user-info-card">
            <h1>Hi, Partner</h1>
            {props.user.userInfo && (
              <section className="user-details">
                <div className="user-detail">
                  <strong>Name:</strong> <span>{props.user.userInfo.name}</span>
                </div>
                <div className="user-detail">
                  <strong>Email:</strong> <span>{props.user.userInfo.email}</span>
                </div>
                <div className="user-detail">
                  <strong>PartnerId:</strong> <span>{props.user.userInfo.groupId}</span>
                </div>
                <div className="user-detail">
                  <strong>Mobile No:</strong> <span>{props.user.userInfo.mobileNo}</span>
                </div>
              </section>
            )}
          </div>
          <GraphComponent data={dashboardData} />
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfo: (token) => dispatch(getUserInfo(token)),
  };
};

const mapStateToProps = ({ EventUser }) => {
  return {
    user: EventUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
