import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const GraphComponent = ({ data }) => {
  const chartData = [
    {
      name: 'Today',
      'Pre-Approved Cases': data?.totalPreApprovedCasesToday || 0,
      'Order Cases': data?.totalOrderCasesToday || 0,
    },
    {
      name: 'This Month',
      'Pre-Approved Cases': data?.totalPreApprovedCasesThisMonth || 0,
      'Order Cases': data?.totalOrderCasesThisMonth || 0,
    },
  ];

  return (
    <div className="chart-container" style={{ width: '50%', height: 300, marginTop: '20px' }}>
      <h4 style={{ textAlign: 'center', color: '#5B5FC7' }}>Comparison of Cases</h4>
      <ResponsiveContainer>
        <BarChart data={chartData} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Pre-Approved Cases" fill="#8884d8" />
          <Bar dataKey="Order Cases" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GraphComponent;
